<template>
  <div>
    <h2>Set a XML feeds</h2>
    <div class="card card-custom example example-compact" v-if="loading_xml">
      <div class="card-body " style="text-align: center">
        <loading-progress
          :indeterminate="true"
          size="64"
          rotate
          fillDuration="2"
          rotationDuration="1"
        />
        <p class="text-dark">Please wait...</p>
      </div>
    </div>
    <div class="card card-custom example example-compact" v-else>
      <form class="form" method="post" id="feeds_form">
        <input type="hidden" name="id" ref="f_id" :value="model.id" />
        <div class="card-body">
          <div
            :class="`alert alert-${message.color}`"
            role="alert"
            v-if="message.show"
          >
            {{ message.text }}
          </div>
          <v-row>
            <div class="form-group col-md-5">
              <label>Feed URL:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="flaticon2-website"></i>
                  </span>
                </div>
                <input
                  type="url"
                  class="form-control"
                  name="feed_url"
                  ref="f_feed_url"
                  placeholder="Enter feed url"
                  :value="model.feed_url"
                />
              </div>
            </div>
            <div class="form-group col-md-2">
              <label>Typ:</label>
              <div class="input-group">
                <select
                  class="form-control"
                  name="feed_type_id"
                  ref="f_feed_type_id"
                >
                  <option
                    v-for="feed_type in feed_types"
                    :value="feed_type.id"
                    :key="feed_type.id"
                    :selected="
                      model.feed_type_id == feed_type.id ? true : false
                    "
                  >
                    {{ feed_type.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-2">
              <label>Name:</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  ref="f_name"
                  placeholder="Interal nema"
                  :value="model.name"
                />
              </div>
            </div>

            <div class="form-group col-md-1">
              <v-switch
                v-model="model.activated"
                ref="f_activated"
                label="Activated"
              ></v-switch>
            </div>
          </v-row>
          <v-row>
            <div class="form-group col-md-2">
              <v-select
                v-model="model.source"
                :items="sources"
                item-text="name"
                item-value="key"
                filled
                label="Source"
                dense
              ></v-select>
            </div>
            <div class="form-group col-md-2" v-if="model.source === 'custom'">
              <label>TAG NAME of items:</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  name="tag_name"
                  ref="f_tag_name"
                  placeholder="TAG NAME"
                  :value="model.tag_name"
                />
              </div>
            </div>
          </v-row>
        </div>
        <div class=" card-footer text-right ">
          <v-btn
            v-on:click.prevent="deleteItem"
            href="#"
            ref="delete"
            :loading="loading.delete"
            v-if="this.$route.query.id > 0"
            color="error"
            class="mr-2"
            >Delete
          </v-btn>
          <v-btn
            v-on:click.prevent="reloadItem"
            href="#"
            ref="reload"
            :loading="loading.reload"
            v-if="this.$route.query.id > 0"
            color="warning"
            class="mr-2"
            >Reload
          </v-btn>
          <v-btn
            v-on:click.prevent="saveItem"
            href="#"
            ref="submit"
            color="primary"
            :loading="loading.submit"
            class="mr-2"
          >
            Submit
          </v-btn>
        </div>
      </form>
    </div>
    <section v-if="this.$route.query.id > 0 && model.fields" class="mt-5">
      <div class="row">
        <div class="col-12">
          <h1 class="card-label">Setup Schema Mapping</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-3" v-for="(value, key) in model.fields" :key="key">
          <div v-if="Array.isArray(value) && value[0].name && value[0].value">
            <div
              v-for="(value1, key1) in value"
              :key="key1"
              class="card card-custom gutter-b"
              :class="{
                'bg-primary':
                  model.mapping[key + '---' + calcuMD5(value1.name)],
                'bg-light':
                  model.mapping[key + '---' + calcuMD5(value1.name)] == 0
              }"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3
                    class="card-label"
                    :class="{
                      'text-white':
                        model.mapping[key + '---' + calcuMD5(value1.name)]
                    }"
                  >
                    {{ value1.name }}
                    <small
                      :class="{
                        'text-white':
                          model.mapping[key + '---' + calcuMD5(value1.name)]
                      }"
                      >{{ value1.value }}</small
                    >
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label
                    v-bind:for="'form__' + key + '---' + calcuMD5(value1.name)"
                    >Assign <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    v-bind:id="'form__' + key + '---' + calcuMD5(value1.name)"
                    v-bind:name="value1.name"
                    v-model="model.mapping[key + '---' + calcuMD5(value1.name)]"
                  >
                    <option value="0">unassigned</option>
                    <option
                      v-for="item in schema_data"
                      :key="item.name"
                      :disabled="isPicked(item.name)"
                      :selected="
                        model.mapping[key + '---' + calcuMD5(value1.name)] ==
                          item.name
                      "
                      >{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="card card-custom gutter-b"
              :class="{
                'bg-primary': model.mapping[key],
                'bg-light': model.mapping[key] == 0
              }"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3
                    class="card-label"
                    :class="{ 'text-white': model.mapping[key] }"
                  >
                    {{ key }}
                    <small :class="{ 'text-white': model.mapping[key] }">{{
                      value
                    }}</small>
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label v-bind:for="'form__' + key"
                    >Assign <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    v-bind:id="'form__' + key"
                    v-bind:name="key"
                    v-model="model.mapping[key]"
                  >
                    <option value="0">unassigned</option>
                    <option
                      v-for="item in schema_data"
                      :key="item.name"
                      :disabled="isPicked(item.name)"
                      :selected="model.mapping[key] == item.name"
                      >{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import md5 from "js-md5";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  PUT_XML_FEED,
  POST_XML_FEED,
  PULL_XML_FEED,
  PULL_XML_FEED_TYPES,
  REFRESH,
  DELETE_FEED,
  RELOAD_FEED
} from "@/core/services/store/xmlfeed.module";
import { PULL_SCHEMAS } from "@/core/services/store/schema.module";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    sources: [
      {
        name: "Custom",
        key: "custom"
      },
      {
        name: "Praguebest Feed",
        key: "mastershop"
      },
      {
        name: "Google",
        key: "google"
      },
      {
        name: "Heureka",
        key: "heureka"
      },
      {
        name: "Shoptet",
        key: "shoptet"
      }
    ],
    loading: {
      submit: false,
      delete: false,
      reload: false
    },
    expand: {}
  }),
  methods: {
    calcuMD5: function(pwd) {
      return md5(pwd);
    },
    isPicked: function(key) {
      if (Array.isArray(this.model.mapping)) {
        for (var p in this.model.mapping) {
          if (this.model.mapping[p] === key) {
            return true;
          }
        }
      }
      return false;
    },
    serialize: obj => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    schemaSerialize: obj => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + ":" + encodeURIComponent(obj[p]));
        }
      return str.join(";");
    },
    getTagName: function() {
      let tagName = this.$refs.f_tag_name
        ? this.$refs.f_tag_name.value
        : this.model.tag_name;

      if (this.model.source != "custom") {
        tagName = this.model.source;
      }

      return tagName;
    },
    deleteItem: function() {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want delete Feed. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.loading.delete = true;
          this.$store.dispatch(DELETE_FEED, this.$route.query.id).then(data => {
            this.loading.delete = false;
            if (data.err === 0) {
              this.$router.push({ name: "import" });
            }
          });
        }
      });
    },
    reloadItem: function() {
      this.loading.reload = true;
      this.$store.dispatch(RELOAD_FEED, this.$route.query.id).then(() => {
        this.loading.reload = false;
      });
    },
    saveItem: function() {
      this.loading.submit = true;
      if (this.$route.query.id !== undefined && this.$route.query.id > 0) {
        this.$store
          .dispatch(PUT_XML_FEED, {
            id: this.$route.query.id,
            data: {
              feed_url: this.$refs.f_feed_url.value,
              name: this.$refs.f_name.value,
              feed_type_id: this.$refs.f_feed_type_id.value,
              activated: this.model.activated ? 1 : 0,
              tag_name: this.getTagName(),
              mapping: this.schemaSerialize(this.model.mapping)
            }
          })
          .then(() => {
            this.loading.submit = false;
          });
      } else {
        this.$store
          .dispatch(POST_XML_FEED, {
            feed_url: this.$refs.f_feed_url.value,
            name: this.$refs.f_name.value,
            activated: this.model.activated ? 1 : 0,
            feed_type_id: this.$refs.f_feed_type_id.value
          })
          .then(data => {
            this.loading.submit = false;
            this.$router.replace({ query: { id: data.id } });
          });
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.xmlfeed.errors,
      message: state => state.message.message,
      model: state => state.xmlfeed.model,
      fields: state => state.xmlfeed.model.fields,
      schema_data: state => state.schema.schema_data,
      feed_types: state => state.xmlfeed.feed_types,
      loading_xml: state => state.xmlfeed.loading
    })
  },
  beforeCreate() {
    this.$store.dispatch(RELOAD_MESSAGE);
    this.$store.dispatch(REFRESH);
    if (this.$route.query.id !== undefined) {
      this.$store.dispatch(PULL_XML_FEED, this.$route.query.id).then(() => {
        this.$store.dispatch(PULL_SCHEMAS, this.model.collection_id);
        this.$store.dispatch(PULL_XML_FEED_TYPES);
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Import", route: "/import" },
      { title: "Edit" }
    ]);
  }
};
</script>
